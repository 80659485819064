/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #7e7e7e;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    float: left;
    margin: 0 10px 30px 0;
  }

}
.font-weight-strong {
  font-weight: 600 !important;
}
.small {
  font-size: 90%;
}
.clearfix {
  clear: both;
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 650px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.25em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-family: 'Roboto Slab', var(--font-family);
      line-height: 1.2;
      color: $theme-primary;
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      letter-spacing: 0.03rem;
      font-family: var(--font-family);
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}
.cta-wrap {
  background: #484f5a;
}
#banner-wrapper {
  background: #f3f7fc;
  &.county {
    .banner-img {
      max-width: 350px;
    }
  }
}
#banner-wrap {
  background: #ddd url(/images/banner-bg.jpg) repeat-x;
  background-size: cover;
}
#banner {
  .details {
    ul.check {
      color: #4176aa;
      font-size: 1.1em;
      li:before {
        color: #484f5a;
      }
    }
  }
}
#points {
  img {
    max-width: 80px;
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 74px;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 1.5em;
    }
    span.small {
      display: inline-block;
    }
  }
  #points {
    img {
      max-width: 120px;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    span.big {
      font-size: 1.9em;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #banner-wrap {
    background-size: contain;
  }
  .banner-img {
    margin-bottom: -80px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1235px) {
  #banner {
    h1 {
      font-size: 2.25em;
    }
  }
}
